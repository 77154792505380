:root {
  --gap: 0.8em;
  /* --primary-color: #081e30; */
}

.footer {
  background-color: rgb(7, 8, 32);
}

.font-primary-color {
  color: #00AFEF;
}

.primary-color {
  color: #00AFEF;
}

.bg-primary-color {
  background-color: #00AFEF;
}

.bg-dark-primary-color {
  background-color: #011125;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1170px;
  margin: auto;
}

.row-client,
.row-contact,
.row-footer {
  display: flex;
  flex-wrap: wrap;
}

ul {
  list-style: none;
}

.footer {
  background-color: #24262b;
  padding: 60px 0 0 0;
}

.footer-col {
  width: 25%;
  padding: 0 15px;
  margin: 10px 0;
}

.footer-col h4,
.footer-col h6 {
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-weight: 500;
  position: relative;
  /* font-family: 'Poppins', sans-serif; */
}

.footer-col p {
  font-size: 13px;
  color: #ffffff;
  /* text-transform: capitalize; */
  /* margin-bottom: 35px; */
  padding-right: 30px;
  font-weight: 500;
  position: relative;
  /* font-family: 'Poppins', sans-serif; */
}

.logo-footer {
  transform: scale(6)
}

.footer-col h4::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  background-color: #00AFEF;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 3px;
}

.footer-col ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}

.footer-col ul li a:hover {
  color: #ffffff;
  padding-left: 8px;
}

.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}

.footer-col .social-links a:hover {
  color: #24262b;
  background-color: #ffffff;
}

.serv-title {
  justify-content: center;
  text-align: center;
  padding-top: 20px !important;
}

.serv-ico-box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 110px;
  min-width: 110px;
}

.copyright {
  padding: 0.3em 1em;
  background-color: #434344;
  color: #ffffff;
  text-align: center;
  margin-top: 60px;
}

.copyright p,
.copyright p a {
  font-size: 0.9em;
  text-align: center;
  color: #ffffff;
  width: 100%;
}



.row-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  /* gap: var(--gap); */
  gap: 0.8em;
  align-items: center;
  row-gap: 2px;
  padding: 0.8em 0;

}

.row-img img {
  margin: 0 1em;
}

.txt-c {
  text-align: center;
}

.txt-white {
  color: #ffffff;
}

.m-0 {
  margin: 0 !important;
}

.icon-footer-cu i {
  height: 10px;
  width: 10px;
}

.footer-cu-spacer {
  min-width: 15px;
}

.no-wrap {
  flex-wrap: nowrap;
}

.footer-icon-container {
  display: flex;
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.img-client-container,
.img-client-container img {
  max-width: 90px;
  max-height: 90px;
}

.flex-c {
  justify-content: center;
  align-items: center;
}

.container-ads {
  /* max-width: 1220px; */
  width: 100%;
  margin-top: 10em;
  height: 500px;
  display: flex;
  justify-content: center;


}

/* .container-ads::after{
  background: url('../../assets/img/cloud.webp') no-repeat;
  background-size: 100%;
  position: absolute;
  z-index: -1;  
  opacity: 0.4;
} */

.img-bg {
  opacity: 0.4;
  position: absolute;
  max-height: 500px;
  min-height: 500px;
  /* max-height: 500px; */
  object-fit: cover;
  width: 100%;
  /* height: 100%; */
  /* object-fit: scale-down; */
  /* left: 0; */
  /* top: 0; */
  width: 100%;
  height: auto;
  /* z-index: -1; */
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  gap: var(--gap);
  align-items: center;
  row-gap: 2px;
  height: 100%;
}

.col-1 {
  width: 100%;
}

.col-2 {
  width: calc((100%/2) - var(--gap));
}

.col-3 {
  width: calc((100%/3) - var(--gap));
}

.col-4 {
  width: calc((100%/4) - var(--gap));
}

.cloud-seeding-container {
  width: 100%;
  max-width: 1160px;
  height: 100%;
  position: relative;
  /* color: rgb(61, 61, 61); */
  /* text-shadow: 0 2px #ffffff; */
}

.flex-ads {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}

.sec-desc-right {
  /* width: 50%; */
  /* position: absolute; */
  /* margin-top: 90px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  /* align-items: center; */
  /* right: 0; */
  padding: 5%;

}

.sec-img-left {
  left: 0;
}

.sec-img-right {
  right: 0;
}

.sec-img-right,
.sec-img-left {
  position: absolute;
  min-height: 100%;
  max-height: 100%;
  top: -90px;
}

/* .sec-desc-right p {
  max-width: 475px;
} */

.sec-left-img1 {
  max-width: 50%;
  margin: 0 6% 10px 6%;
}

.sec-left-img2 {
  max-width: 30%;
  margin: 0 5% 10px 5%;
}

.sec-left-img3 {
  width: 20%;
  margin-left: 40%;
}

.sec-left-img4 {
  width: 30%;
  margin: 0 5%auto;
}

.sec-left-img1,
.sec-left-img2,
.sec-left-img3,
.sec-left-img4 {
  height: auto;
  border-radius: 1rem;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
}

.about {
  margin: 9em 0;
}

.fx-c {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.h-100{
  height: 100%;
}

.w-100{
  width: 100%;
}

.sec-img-gallery{
  width: 100%;
  height: 100%;
}



@media (max-width: 1160px) {
  .flex-ads {
    padding: 100px 0 40px 0;
  }
}

@media (max-width: 860px) {

  .flex-ads {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }

  .sec-desc-right {
    /* width: 80%; */
    position: relative;
    /* order: 1; */
    /* margin-top: 400px; */
    top: -90px;
  }

  .container-ads {
    margin-top: 6em;
  }

  .sec-img-left,
  .sec-img-right {
    scale: 0.8;
    /* width: 70%; */
    /* height: 70%; */
    position: relative;
    order: 1;
    top: -90px;
    /* transform: scale(0.7); */
  }

  .container {
    padding: 0 30px;
  }

  .container-ads, .img-bg{
    min-height: 320px;
    max-height: 320px;
    height: 320px;
  }
}

/*responsive*/
@media(max-width: 767px) {
  .footer-col {
    width: 50%;

  }

  .sec-img-left,
  .sec-img-right {
    width: 70%;
    height: 70%;
    position: relative;
    order: 1;
    top: -90px;
    /* transform: scale(0.7); */
  }

  .fx-rev {
    flex-direction: column-reverse;
  }

  .mob-ns {
    display: none;
  }
}

@media(max-width: 574px) {
  .footer-col {
    width: 100%;
  }

  .mob-ns {
    display: none;
  }

  .fx-rev {
    flex-direction: column-reverse;
  }

  [class*="col-"] {
    width: 100%;
    padding: 0 20px;
  }

  .sec-left-img1 {
    max-width: 60%;
    margin: 0;
    /* margin: 0 6% 10px 6%; */
  }

  .sec-left-img2 {
    max-width: 30%;
    margin: 0;
    /* margin: 0 5% 10px 5%; */
  }

  .row-img img {
    margin: 0;
  }

  .sec-img-left,
  .sec-img-right {
    width: 70%;
    height: 70%;
    position: relative;
    order: 1;
    top: -90px;
    /* transform: scale(0.7); */
  }

  .container-ads, .img-bg{
    min-height: 400px;
    max-height: 400px;
    height: 400px;
  }
}